import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Icon from '~components/Icon'

import * as s from './Cta.module.scss'

const Cta = () => {
  const { t } = useTranslation()
  return (
    <section className={s.cta_wrapper}>
      <Container className={s.cta}>
        <div className={s.cta_pic}>
          <Icon name="cta" size={50} />
        </div>
        <h2 className={s.cta_title}>{t('cta.title')}</h2>
        <p>{t('cta.descr')}</p>
      </Container>
    </section>
  )
}

export default Cta
