import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Cards from '~components/Cards'

import * as s from './Recent.module.scss'

const Recent = ({ list }) => {
  const { t } = useTranslation()
  return (
    <Container as="section" className={s.recent}>
      <h2>{t('recent.title')}</h2>
      <Cards list={list} />
    </Container>
  )
}

export default Recent
