import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Icon from '~components/Icon'
import { Link } from 'gatsby'

import * as s from './Categories.module.scss'
import CATEGORIES from './constants'

const Categories = () => {
  const { t } = useTranslation()
  return (
    <Container as="section" className={s.categories}>
      <h2>{t('categories.title')}</h2>
      <div className={s.categories_list}>
        {CATEGORIES.map((el) => (
          <Link
            key={el}
            to="/catalogue"
            state={{ type: el }}
            className={s.categories_link}
          >
            <div className={s.categories_link_inner}>
              <div className={s.categories_link_front}>
                <Icon key={el} name={el} size={75} />
              </div>
              <div className={s.categories_link_back}>
                <span>{t(`categories.${el}`)}</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Container>
  )
}

export default Categories
