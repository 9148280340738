import React, { useEffect, useState } from 'react'
import Layout from '~components/Layout'
import S from '~components/seo'
import useHttp from '~hooks/useHttp'
import Categories from './components/Categories'
import Cta from './components/Cta'
import Hero from './components/Hero'
import Recent from './components/Recent'

const Home = () => {
  const [cards, setCards] = useState([])
  const { request } = useHttp()

  const getCards = async () => {
    const list = await request(`https://refhelpapp.herokuapp.com/cards`, 'GET')
    setCards(list)
  }

  useEffect(() => {
    getCards()
  }, [])
  return (
    <Layout>
      <S title="Home" />
      <Hero />
      <Categories />
      <Recent list={cards} />
      <Cta />
    </Layout>
  )
}

export default Home
