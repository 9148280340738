import { graphql } from 'gatsby'
import React from 'react'

import Home from '~containers/Home'

const IndexPage = ({ data }) => <Home {...data} />

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
