import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as s from './Hero.module.scss'

const Hero = () => {
  const { t } = useTranslation()
  return (
    <Container as="section" className={s.hero}>
      <StaticImage
        src="./img/refhelphero-min.png"
        alt="hero"
        width={628}
        placeholder="blurred"
        quality={100}
      />
      <div className={s.hero_info}>
        <h1>{t('layout.title')}</h1>
        <p>
          {[...Array(3)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`a-${i}`}>{t(`layout.aboutUs${i + 1}`)}</span>
          ))}
        </p>
        <div className={s.hero_buttons}>
          <Button variant="outline-secondary">{t('buttons.wantHelp')}</Button>
          <Button variant="primary">{t('buttons.needHelp')}</Button>
        </div>
      </div>
    </Container>
  )
}

export default Hero
